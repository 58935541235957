window.article = function (id, likes) {
    return {
        id: id,
        liked: localStorage.getItem(`partageonsdesign-like-${id}`) === 'true' ? true : false,
        likes: parseInt(likes),
        lastClick: 0,
        isOpenByTap: false,
        shareIsHover: null,
        isCopied: false,
        openAnchor() {
            this.$refs.anchor.click()
        },
        likeButtonClicked() {
            const now = Date.now()
            const delta = now - this.lastClick
            this.lastClick = now
            if (delta < 500) { return }
            this.liked = !this.liked
            this.likes += this.liked ? 1 : -1
            localStorage.setItem(`partageonsdesign-like-${this.id}`, this.liked)
            fetch(`/api/like/?id=${this.id}&status=${this.liked ? 1 : 0}`)
                .then( response => response.text() )
                .then( text => console.log(text) )
                .catch( error => console.log(error) )
        },
        onShareHover(target) {
            this.shareIsHover = target
        },
        isShareHovered(target) {
            return { 'hover' : this.shareIsHover === target}
        },
        toggleShareOnMobile() {
            this.isOpenByTap = !this.isOpenByTap
        },
        hideShareButtons() {
            this.isOpenByTap = false
        },
        copyToClipboard() {
            this.hideShareButtons()
            this.$refs.url.style.display = 'block'
            this.$refs.url.select()
            this.$refs.url.setSelectionRange(0, 99999)
            document.execCommand("copy")
            this.isCopied = true
            this.$refs.url.style.display = 'none'
            setTimeout(() => {
                this.isCopied = false
            }, 2000)
        }
    }
}
