const pointerCanvas = document.querySelector('#js-customPointer')
const ctx = pointerCanvas.getContext('2d')
const particles = []

/**
 * Manage cursor animation
 */
const moveCursor = () => {
    requestAnimationFrame(moveCursor)
    if (lastPos === null) { return }
    // Set lastPos
    lastPos.x = lastPos.x + ((cursor.x - lastPos.x) / 5)
    lastPos.y = lastPos.y + ((cursor.y - lastPos.y) / 5)
    // Set context
    ctx.clearRect(0,0,screen.width,screen.height)
    ctx.fillStyle = '#eb9c00'
    ctx.globalAlpha = 1
    // Draw pointer
    ctx.roundedRect(lastPos.x, lastPos.y, 12, 12, 4)
    ctx.fill()
    // Draw particles
    for (const i in particles) {
        if (!particles[i].draw(ctx)) {
            particles.splice(i, 1)
        }
    }
}
moveCursor()

window.addEventListener('click', (e) => {
    const type = e.target.getAttribute('data-particletype') ?? 'rect'
    particles.push(new Particle( 0.8, 0.8, type))
    particles.push(new Particle( 0.8,-0.8, type))
    particles.push(new Particle(-0.8,-0.8, type))
    particles.push(new Particle(-0.8, 0.8, type))
    particles.push(new Particle(   1,   0, type))
    particles.push(new Particle(  -1,   0, type))
    particles.push(new Particle(   0,  -1, type))
    particles.push(new Particle(   0,   1, type))
})

/**
 * Manage canvas size
 */
const resizeCanvas = () => {
    pointerCanvas.width = screen.width
    pointerCanvas.height = screen.height
}
window.addEventListener('resize',resizeCanvas)
resizeCanvas()