window.header = function() {
    return {
        isSubscribeOpen: window.location.href.includes('/#follow'),
        error: false,
        isSubscriptionSuccessful: false,
        isCopied: false,
        subscribeToNewsletter(e) {
            const data = new FormData(e.target)
            if (data.get('js-phone-verif') != '') { return }
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.get('email')))) {
                this.error = true
                return
            }
            fetch(`/api/joinNewsletter/`, {
                method: 'POST',
                body: data
            })
                .then( response => response.json() )
                .then( json => this.handleSubscriptionSuccess(json) )
                .catch ( error => {
                    window.alert(error)
                    console.log(error)
                    this.error = true
                })
        },
        handleSubscriptionSuccess(json) {
            json.error ? this.error = true : this.isSubscriptionSuccessful = true
        },
        copyRSSToClipboard() {
            this.$refs.url.style.display = 'block'
            this.$refs.url.select()
            this.$refs.url.setSelectionRange(0, 99999)
            document.execCommand("copy")
            this.isCopied = true
            this.$refs.url.style.display = 'none'
            setTimeout(() => {
                this.isCopied = false
            }, 2000)
        }
    }
}