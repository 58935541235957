const postsBox = document.querySelector('#js-postsBox')

const postsLoader = {
    isLoading: false,
    lastOffset: 0,
    handleScroll() {
        if (document.readyState !== 'complete' || postsLoader.isLoading) { return }
        if (window.scrollY > screen.totalHeight - 1000) {
            const query = document.body.getAttribute('data-query')
            const q = query ? `&q=${query}` : ``
            postsLoader.lastOffset += 1
            postsLoader.isLoading = true
            fetch(`/api/getPosts/?offset=${postsLoader.lastOffset}&asHtml=true${q}`)
                .then( response => response.json())
                .then( json => postsLoader.handleLoadedPosts(json) )
                .catch ( error => {
                    console.log(error)
                    postsLoader.isLoading = false
                })
        }
    },
    handleLoadedPosts(json) {
        if (json.length == 0) { return }
        json.forEach(post => {
            postsBox.innerHTML += post
        })
        screen.set()
        postsLoader.isLoading = false
    }
}

if (postsBox) {
    document.addEventListener('scroll', postsLoader.handleScroll)
}