CanvasRenderingContext2D.prototype.roundedRect = function (xDest, yDest, width, height, radius) {
    const x = xDest - (width/2)
    const y = yDest - (height/2)
    if (width < 2 * radius) radius = width / 2
    if (height < 2 * radius) radius = height / 2
    this.beginPath()
    this.moveTo(x+radius, y)
    this.arcTo(x+width, y,   x+width, y+height, radius)
    this.arcTo(x+width, y+height, x,   y+height, radius)
    this.arcTo(x,   y+height, x,   y,   radius)
    this.arcTo(x,   y,   x+width, y,   radius)
    this.closePath()
    return this
}

CanvasRenderingContext2D.prototype.heart = function(x, y) {
    this.beginPath()
    this.moveTo(x, y)
    this.lineTo(x - 8, y - 7)
    this.arc(x - 4, y - 11.5, 6, Math.PI * 0.75, Math.PI * 1.75)
    this.arc(x + 4, y - 11.5, 6, Math.PI * 1.25, Math.PI * 2.25)
    this.lineTo(x + 8, y - 7)
    this.lineTo(x, y)
    return this
}

class Particle {

    constructor(speedX, speedY, type) {
        this.x = cursor.x
        this.y = cursor.y
        this.speedX = speedX
        this.speedY = speedY
        this.type = type
        this.initializationTimestamp = Date.now()
    }

    getYPos(progression) {
        return this.y + (progression * this.speedY * 80)
    }

    getXPos(progression) {
        return this.x + (progression * this.speedX * 80)
    }

    draw(ctx) {
        const progression = (Date.now() - this.initializationTimestamp) / 300
        ctx.globalAlpha = Math.max(1 - progression, 0)
        switch (this.type) {
            case 'rect':
                ctx.roundedRect(this.getXPos(progression), this.getYPos(progression), 6, 6, 2)
                break
            case 'heart':
                ctx.heart(this.getXPos(progression), this.getYPos(progression))
                break;
            default:
                console.log(`unknown type ${this.type}`)
                break
        }
        ctx.fill()
        return progression < 1
    }

}