/*
-----------
Global
-----------
*/

const screen = {
    width: window.innerWidth,
    height: window.innerHeight,
    totalHeight: document.body.clientHeight,
    set() {
        screen.width = window.innerWidth
        screen.height = window.innerHeight
        const body = document.body
        const html = document.documentElement
        screen.totalHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight ) - screen.height
    }
}
const cursor = { x: screen.width / 2, y: screen.height / 2 }
let lastPos = null

window.addEventListener('resize', screen.set)
document.addEventListener('readystatechange', screen.set)

window.addEventListener('mousemove', (e) => {
    cursor.x = e.clientX
    cursor.y = e.clientY
    if (lastPos === null) {
        lastPos = { x: cursor.x, y: cursor.y }
    }
})